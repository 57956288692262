import LinkButton from "components/LinkButton";
import { useRouter } from "next/router";

export default function LoginRequired() {
  const router = useRouter();

  return (
    <div className="flex flex-col gap-y-8">
      <h1 className="h1">Login Required</h1>
      <p className="p">You must be logged in to view this page.</p>
      <div className="flex justify-center">
        <LinkButton
          href={`/login?ret=${encodeURIComponent(router.asPath)}`}
          className="btn-primary w-full md:w-[20rem]"
        >
          Login
        </LinkButton>
      </div>
    </div>
  );
}
